import React, { useEffect, useState } from "react";
import Heading from "../../../components/general/Heading";
import { useNavigate, useParams } from "react-router-dom";
import noPhoto from "../../../assets/images/undraw_Empty_street_re_atjq.png";
import { getRegionTownHealthCare } from "../../../api/requests/healthcareRequest";
import DataTableView from "../../../components/admin/DataTableView";
import { getRegionHealthcareOrgDetails } from "../../../api/requests/healthcareRequest";
const RegionHealthOrgDetails = () => {
  const [tableContent, setTableContent] = useState("");
  const [info, setInfo] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const onRowClicked = (town) => {
    navigate("/home/healthcare/town/" + town.id, {
      replace: true,
    });
  };
  useEffect(() => {
    (async () => {
      getRegionTownHealthCare(id)
        .then((res) => {
          const headers = ["Наименование", "Область", "Район/Город", "Адрес"];
          const showOnly = ["name", "region.nameUZ", "city.nameUZ", "address"];
          setTableContent(
            <DataTableView
              headers={headers}
              showOnly={showOnly}
              data={res.data.thos}
              onRowClicked={onRowClicked}
            />
          );
        })
        .catch((e) => {});

      getRegionHealthcareOrgDetails(id)
        .then((res) => {
          const item = res.data.rho;
          setInfo(
            <div>
              {" "}
              <Heading goBack="/home/healthcare/region">{item.name}</Heading>
              <div className="text-center font-bold p-5">
                Краткая информация
              </div>
              <div className="grid grid-cols-3 gap-3 w-full border border-slate-200 p-4 rounded-lg">
                <div className="row-span-3 border-r-2">
                  <img src={item.photo != null ? item.photo : noPhoto} />
                </div>
                <div>
                  <div className="font-bold">Наименование</div>
                  <div>{item.name}</div>
                </div>
                <div>
                  <div className="font-bold">Область</div>
                  <div>{item.region.nameUZ}</div>
                </div>
                <div>
                  <div className="font-bold">Город/Район</div>
                  <div>{item.city.nameUZ}</div>
                </div>
              </div>
            </div>
          );
        })
        .catch((e) => {});
    })();
  }, []);

  return (
    <div className="bg-white">
      {info}
      <div className="text-center font-bold p-5">
        Городские/Районные отделения управления здравохранения
      </div>
      <div>{tableContent}</div>
    </div>
  );
};

export default RegionHealthOrgDetails;
