import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SimpleCardForm from "../../../components/forms/SimpleCardForm";
import { typeOfContrs } from "../../../assets/arrays/types";
import { createContr } from "../../../api/requests/healthcareRequest";

const AddEditContrType = () => {
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    formState: { isLoading },
  } = useForm();

  const onSubmit = async (data) => {
    await createContr(data)
      .then((res) => {
        if (res.status == 200)
          navigate("/home/healthcare/contr/list", { replace: true });
      })
      .catch((err) => {
        setErrMessage(err.response.data.message);
      });
  };

  const fields = [
    {
      name: "name",
      placeholder: "Наименование",
      label: "Наименование",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "description",
      type: "textarea",
      label: "Описание",
      placeholder: "Описание",
      validation: { required: "This field is required" },
      maxLength: { value: 150, message: "Not more than 150 characters" },
    },
    {
      name: "type",
      type: "select",
      label: "Тип",
      icon: null,
      validation: {
        required: "Заполните поле",
      },
      options: typeOfContrs,
    },
  ];

  return (
    <div>
      <SimpleCardForm
        title={"Добавить тип контрацептива"}
        register={register}
        errMessage={errMessage}
        errors={errors}
        fields={fields}
        onSubmit={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default AddEditContrType;
