import React from "react";
import { useNavigate } from "react-router-dom";

const Heading = ({ children, goBack = "" }) => {
  const navigate = useNavigate();

  return (
    <div className="text-xl font-bold pl-2 py-4 flex text-center content-center align-middle m-auto">
      {goBack != "" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 my-auto cursor-pointer active:border active:border-white"
          onClick={() => navigate(goBack, { replace: true })}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 my-auto cursor-pointer active:border active:border-white"
          onClick={() => navigate(-1)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      )}

      {children}
    </div>
  );
};

export default Heading;
