import React, { useEffect, useState } from "react";
import DataTableView from "../admin/DataTableView";
import CustomInput from "../forms/CustomInput";
import { useForm } from "react-hook-form";
import DefaultButton from "../buttons/DefaultButton";

const PagingTable = ({ getDataFunction, headers, fields, onRowClicked }) => {
  const [table, setTable] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState();
  const [searchTerm, setSearchTerm] = useState("none");

  const onSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    setOffset(0);
  };

  useEffect(() => {
    (async () => {
      await getDataFunction(offset, limit, searchTerm)
        .then((res) => {
          if (
            res.data.data != null &&
            res.data.data.length > 0 &&
            res.data.data.length != undefined &&
            Array.isArray(res.data.data)
          ) {
            setCount(res.data.total);
            setTable(
              <DataTableView
                headers={headers}
                showOnly={fields}
                onRowClicked={onRowClicked}
                data={res.data.data}
              />
            );
          } else {
            setCount(res.data.total);
            setTable(<div className="">Ничего не найдено</div>);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, [offset, searchTerm]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="flex flex-col mb-5 p-3 float-right relative">
          <label className="mb-1 text-xs tracking-wide text-gray-600 flex">
            Поиск:
          </label>
          <div className="relative w-full">
            <input
              placeholder="Введите текст для поиска"
              type="text"
              className="text-md placeholder-gray-500 pl-2 pr-4 rounded-2xl border  
                   py-2 focus:outline-none focus:border-blue-400 border-gray-400"
              onChange={onSearchTermChange}
            ></input>
          </div>
        </div>
      </div>
      {table}{" "}
      <div className="p-4 float-right text-right">
        {count > limit && (
          <div className="flex flex-row content-end">
            {offset >= limit && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 float-right cursor-pointer"
                onClick={() => {
                  setOffset(offset - limit);
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            )}
            {count > offset + limit && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 float-right cursor-pointer"
                onClick={() => {
                  setOffset(offset + limit);
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PagingTable;
