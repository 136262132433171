import React, { useState } from "react";
import DefaultButton from "../buttons/DefaultButton";
import { useForm } from "react-hook-form";
import CustomInput from "./CustomInput";
import { useDispatch } from "react-redux";
import { login } from "../../store/user_slice";
import { useNavigate } from "react-router-dom";
import SimpleLink from "../links/SimpleLink";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (await dispatch(login(data, setErrMessage))) {
      navigate("/home", { replace: true });
    }
  };
  const [errMessage, setErrMessage] = useState("");

  const fields = [
    {
      name: "username",
      placeholder: "998 XX XXX XX XX",
      label: "Username",
      type: "text",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="orange"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
          />
        </svg>
      ),
      validation: {
        required: "This field is required",
        maxLength: { value: 50, message: "Not more than 50 characters" },
        pattern: {
          value: /^(\d{3})?\(?\d{2}\)?\d{3}\d{4}$/,
          message: "Enter valid email address",
        },
      },
    },
    {
      name: "password",
      placeholder: "Enter your password",
      label: "Password",
      type: "password",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="orange"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
          />
        </svg>
      ),
      validation: {
        required: "This field is required!",
        maxLength: { value: 30, message: "Not more than 30 characters" },
      },
    },
  ];

  return (
    <div className="content-center w-96 p-14">
      <h1 className="text-center pb-4 uppercase font-bold text-xl">
        Войти в систему
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => {
          return (
            <CustomInput
              key={field.name}
              {...field}
              errors={errors[field.name] && errors[field.name].message}
              register={register}
            >
              {field.icon}
            </CustomInput>
          );
        })}
        <div className="text-red-500 p-2 text-sm">{errMessage}</div>
        <div className="my-auto align-middle content-center flex justify-around">
          <SimpleLink to="/registration">Зарегистрироваться</SimpleLink>
          <DefaultButton text={"Войти"} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Login;
