import React from "react";
import NavigationMenu from "./navigation/Navigation";

const LeftMenu = () => {
  return (
    <div className="border-r-2 h-screen w-60 fixed left-0 top-20 overflow-scroll z-10">
      <NavigationMenu />
    </div>
  );
};

export default LeftMenu;
