import React, { useEffect, useState } from "react";

const FloatingMessage = ({ popUpState, setPopUpState }) => {
  useEffect(() => {
    setTimeout(() => {
      if (popUpState.show) {
        setPopUpState({ ...popUpState, show: false });
      }
    }, 5000);
  }, [popUpState]);

  return (
    <div
      className={` border ${popUpState.show ? "visible" : "hidden"} ${
        popUpState.type == "error"
          ? "border-red-700 bg-red-200"
          : "border-green-700 bg-green-200"
      } rounded-xl w-fit absolute shadow-lg right-10 top-10 z-50`}
    >
      <div className="w-fit float-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 cursor-pointer"
          onClick={() => {
            setPopUpState({ ...popUpState, show: false });
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div className="py-5 px-10">
        <div
          className={`relative uppercase font-bold ${
            popUpState.type == "error" ? "text-red-900" : "text-green-900"
          } `}
        >
          {popUpState.heading}
        </div>
        <div
          className={`relative ${
            popUpState.type == "error" ? "text-red-900" : "text-green-900"
          } `}
        >
          {popUpState.message}
        </div>
      </div>
    </div>
  );
};

export default FloatingMessage;
