import React from "react";
import Heading from "../../../components/general/Heading";
import { useNavigate } from "react-router-dom";
import PagingTable from "../../../components/tables/PagingTable";
import { getPersonnelPages } from "../../../api/requests/healthcareRequest";

const ListOfPersonnel = () => {
  const navigate = useNavigate();

  const onRowClicked = (personnel) => {
    navigate("/home/healthcare/users/" + personnel.id, { replace: true });
  };

  const headers = [
    "Имя",
    "Фамилия",
    "Отчество",
    "Дата Рождения",
    "Область",
    "Район/Город",
    "Адрес",
    "Номер телефона",
    "Добавлен",
  ];

  const fields = [
    "firstName",
    "lastName",
    "middleName",
    "DOB",
    "region.nameRU",
    "city.nameRU",
    "address",
    "phoneNumber",
    "createdAt",
  ];

  return (
    <div className="w-full">
      <div className="w-full">
        <Heading>Список добавленных больниц, поликлиник и мед. пунктов</Heading>
        <div className="w-full">
          <PagingTable
            headers={headers}
            fields={fields}
            getDataFunction={getPersonnelPages}
            onRowClicked={onRowClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default ListOfPersonnel;
