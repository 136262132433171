import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRegionsHealthCare } from "../../../api/requests/healthcareRequest";
import DataTableView from "../../../components/admin/DataTableView";
import Heading from "../../../components/general/Heading";

const RegionHealthOrgs = () => {
  const [healthCareRegion, setHealthCareRegion] = useState("");
  const navigate = useNavigate();

  const onRowClicked = (item) => {
    navigate("/home/healthcare/region/" + item.id, {
      replace: true,
      state: { item },
    });
  };
  useEffect(() => {
    (async () => {
      getRegionsHealthCare()
        .then((res) => {
          const headers = ["Наименование", "Область", "Район/Город", "Адрес"];
          const showOnly = ["name", "region.nameUZ", "city.nameUZ", "address"];
          setHealthCareRegion(
            <DataTableView
              headers={headers}
              showOnly={showOnly}
              data={res.data.rhos}
              onRowClicked={onRowClicked}
            />
          );
        })
        .catch((err) => {});
    })();
  }, []);

  return (
    <div>
      <Heading>Областные управления здравохранением</Heading>
      {healthCareRegion}
    </div>
  );
};

export default RegionHealthOrgs;
