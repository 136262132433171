import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../store/user_slice";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await dispatch(logout());
      navigate("/", { replace: true });
    })();
  });
  return <></>;
};

export default Logout;
