import api from "../api";

export const getUser = () => {
  return api
    .get("/user/getuser")
    .then((res) => {
      return res.data.user;
    })
    .catch((err) => {
      return null;
    });
};

export const logout = () => {
  return api
    .get("/user/logout")
    .then((res) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
};

export const getUserInformation = () => {
  return api.get("/user/gui");
};

export const updateUserInformation = (data) => {
  return api.post("/user/uui", data);
};

export const createNewUser = (data) => {
  return api.post("/user/cnu", data);
};
