import React from "react";
import Card from "../../../components/admin/cards/Card";
import PagingTable from "../../../components/tables/PagingTable";
import { getAllPatiens } from "../../../api/requests/healthcareRequest";
import { useNavigate } from "react-router-dom";

const ListOfPatients = () => {
  const navigate = useNavigate();
  const onRowClicked = (item) => {
    navigate("/home/healthcare/patient/" + item.id, {
      replace: true,
      state: item,
    });
  };

  return (
    <div>
      <Card header={"Список пациентов"} key={1}>
        <PagingTable
          onRowClicked={onRowClicked}
          getDataFunction={getAllPatiens}
          headers={[
            "Имя",
            "Фамилия",
            "Отчество",
            "Дата рождения",
            "ПИНФЛ",
            "Пол",
            "Область",
            "Город/Район",
            "Адрес",
          ]}
          fields={[
            "firstName",
            "lastName",
            "middleName",
            "DOB",
            "ssn",
            "gender",
            "region.nameUZ",
            "city.nameUZ",
            "address",
          ]}
        />
      </Card>
    </div>
  );
};

export default ListOfPatients;
