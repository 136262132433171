import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SimpleCardForm from "../../forms/SimpleCardForm";
import ContrSelect from "../../forms/ContrSelect";
import LeftActionMenu from "../../admin/cards/LeftActionMenu";
import { createWimex } from "../../../api/requests/healthcareRequest";

const AddEditContrModal = ({ patient, setModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const fields = [
    {
      name: "contrs",
      type: "contrs",
      item: <ContrSelect register={register} />,
    },
    {
      name: "type",
      placeholder: "Тип контрацептива",
      label: "Тип",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "amount",
      placeholder: "Количество",
      label: "Количество",
      type: "number",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "orderNumber",
      placeholder: "Номер ордера",
      label: "Номер ордера",
      type: "number",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "comments",
      type: "textarea",
      label: "Комментарии",
      placeholder: "Комментарии",
      validation: { required: "This field is required" },
      maxLength: { value: 150, message: "Not more than 150 characters" },
    },
    {
      name: "userId",
      type: "hidden",
      value: patient.user.id,
    },
  ];

  const actionMenu = (
    <LeftActionMenu>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer active:border"
        onClick={() => {}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
        />
      </svg>
    </LeftActionMenu>
  );

  const [errMessage, setErrMessage] = useState("");
  const [message, setMessage] = useState();

  const onSubmit = async (data) => {
    createWimex(data)
      .then((res) => {
        reset();
        setMessage(res.data.message);
      })
      .catch((err) => {
        console.log(err.response);
        setErrMessage(err.response.data.message);
      });
  };
  return (
    <SimpleCardForm
      title={
        <div className="flex flex-row">
          <div className="grow">
            Добавить историю о контрацепции.{" "}
            <b>
              Пациент: {patient.firstName} {patient.lastName}{" "}
            </b>
          </div>
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 float-right cursor-pointer"
              onClick={() => {
                setModal(false);
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      }
      register={register}
      errMessage={errMessage}
      errors={errors}
      fields={fields}
      onSubmit={handleSubmit(onSubmit)}
      actionMenu={LeftActionMenu}
      message={message}
    />
  );
};

export default AddEditContrModal;
