import React, { useState } from "react";
import { Link } from "react-router-dom";

const DropDownMenu = ({ items }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const iconDown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 bg-gray-300 rounded-full"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
  const iconUp = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 bg-gray-300 rounded-full"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 15.75l7.5-7.5 7.5 7.5"
      />
    </svg>
  );

  return (
    <div
      className="my-auto cursor-pointer"
      onClick={() => {
        handleClick();
      }}
    >
      {open ? iconUp : iconDown}
      <div
        className={`absolute z-full mt-3 w-screen -translate-x-[90%] max-w-xs transform px-2 sm:px-0 ${
          open ? "visible" : "hidden"
        }`}
      >
        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            {items.map((item) => {
              return (
                <Link
                  to={item.to}
                  key={item.label}
                  className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                >
                  {item.icon}
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      {item.label}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDownMenu;
