import React, { useEffect } from "react";
import { useState } from "react";
import { getRegions, getTowns } from "../../api/requests/regionRequest";

const RegionsTownsSelect = (props) => {
  const [regions, setRegions] = useState(null);
  const [optionsRegion, setOptionsRegion] = useState("");

  const [towns, setTowns] = useState(null);
  const [optionsTown, setOptionsTown] = useState("");

  const [selectedRegion, setSelectedRegion] = useState(1);
  const [selectedTown, setSelectedTown] = useState(15);

  const getRegionTowns = (regionId) => {
    getTowns(regionId).then((res) => {
      setTowns(res.data.towns);
    });
  };

  const onRegionChange = (event) => {
    setSelectedRegion(parseInt(event.target.value));
    getRegionTowns(event.target.value);
  };

  useEffect(() => {
    (async () => {
      getRegions().then((res) => {
        setRegions(res.data.regions);
      });
    })();
  }, []);

  useEffect(() => {
    if (props.region != null) {
      setSelectedRegion(props.region);
    }
  }, [props.region]);

  useEffect(() => {
    if (props.town != null) {
      setSelectedTown(props.town);
    }
  }, [props.town]);

  useEffect(() => {
    if (regions != null) {
      getRegionTowns(props.region);
      if (regions.length > 0) {
        setOptionsRegion(
          regions.map((region) => {
            return (
              <option key={region.id} value={region.id}>
                {region.nameRU}
              </option>
            );
          })
        );
      }
    }
  }, [regions]);

  useEffect(() => {
    if (towns != null) {
      if (towns.length > 0) {
        setOptionsTown(
          towns.map((town) => {
            return (
              <option key={town.id} value={town.id}>
                {town.nameRU}
              </option>
            );
          })
        );
        if (selectedRegion != props.region) {
          setSelectedTown(towns[0].id);
          props.setValue("cityId", towns[0].id);
        } else {
          props.setValue("cityId", props.town);
          setSelectedTown(props.town);
        }
      }
    }
  }, [towns]);

  return (
    <div className="flex flex-row">
      <div className="flex flex-col mb-5 w-full p-3">
        <label className="mb-1 text-xs tracking-wide text-gray-600 flex">
          Область:
        </label>
        <div className="relative w-full">
          <div
            className="
                inline-flex
                items-center
                justify-center
                absolute
                left-0
                top-0
                h-full
                w-10
                text-gray-400
              "
          ></div>

          <select
            name="regionId"
            value={selectedRegion}
            {...props.register("regionId", {
              required: "Заполните данное поле",
              onChange: (e) => {
                onRegionChange(e);
              },
            })}
            className={`text-lg bg-white
                placeholder-gray-500 ${
                  props.children == null ? "pl-2" : "pl-10"
                } 
                pr-4 rounded-2xl border  
                w-full py-2 focus:outline-none 
                ${
                  props.errors != null
                    ? "focus:border-red-500 border-red-500"
                    : "focus:border-blue-400 border-gray-400"
                }`}
          >
            {optionsRegion}
          </select>
        </div>
        {props.errors && (
          <p className="text-red-500 text-sm font-sans flex">{props.errors}</p>
        )}
      </div>
      <div className="flex flex-col mb-5 w-full p-3">
        <label className="mb-1 text-xs tracking-wide text-gray-600 flex">
          Город/Район:
        </label>
        <div className="relative w-full">
          <div
            className="
                inline-flex
                items-center
                justify-center
                absolute
                left-0
                top-0
                h-full
                w-10
                text-gray-400
              "
          ></div>

          <select
            key="cityId"
            name="cityId"
            value={selectedTown}
            {...props.register("cityId", {
              required: "Заполните данное поле",
              onChange: (e) => {
                setSelectedTown(e.target.value);
              },
            })}
            className={`text-lg bg-white
                placeholder-gray-500 ${
                  props.children == null ? "pl-2" : "pl-10"
                } 
                pr-4 rounded-2xl border  
                w-full py-2 focus:outline-none 
                ${
                  props.errors != null
                    ? "focus:border-red-500 border-red-500"
                    : "focus:border-blue-400 border-gray-400"
                }`}
          >
            {optionsTown}
          </select>
        </div>
        {props.errors && (
          <p className="text-red-500 text-sm font-sans flex">{props.errors}</p>
        )}
      </div>
    </div>
  );
};

export default RegionsTownsSelect;
