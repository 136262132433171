import React from "react";
import { Link } from "react-router-dom";

const NavigationLink = ({ name, to }) => {
  return (
    <div>
      <Link
        to={to}
        className="p-2 text-gray-500 font-bold font-mono uppercase hover:text-black hover:border-b-2 hover:border-black"
        key={name}
      >
        {name}
      </Link>
    </div>
  );
};

export default NavigationLink;
