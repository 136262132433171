import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Heading from "../../../components/general/Heading";
import noPhoto from "../../../assets/images/undraw_Empty_street_re_atjq.png";
import {
  getTownHealthcareOrgDetails,
  getTownHospitals,
} from "../../../api/requests/healthcareRequest";
import IconLinksGroup from "../../../components/buttons/IconLinksGroup";
import DataTableView from "../../../components/admin/DataTableView";

const TownHealthCareOrgDetails = () => {
  const [info, setInfo] = useState("");
  const [hospitals, setHospitals] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const onRowClicked = (hospital) => {
    navigate("/home/healthcare/hospital/" + hospital.id, {
      replace: true,
    });
  };

  const links = [
    {
      text: "Добавить поликлинику",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
            clipRule="evenodd"
          />
        </svg>
      ),
      to: "/home/healthcare/hospital/addedit",
    },
    {
      text: "Добавить персонал",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
        </svg>
      ),
      to: "/home/healthcare/hospital/addedit",
    },
  ];

  useEffect(() => {
    (async () => {
      getTownHealthcareOrgDetails(id).then((res) => {
        const town = res.data.tho;
        setInfo(
          <div>
            <Heading goBack={`/home/healthcare/region/${town.regionId}`}>
              {town.name}
            </Heading>
            <div className="text-center font-bold p-5">Краткая информация</div>
            <div className="grid grid-cols-3 gap-3 w-full border border-slate-200 p-4 rounded-lg">
              <div className="row-span-3 border-r-2">
                <img src={town.photo != null ? town.photo : noPhoto} />
              </div>
              <div>
                <div className="font-bold">Наименование</div>
                <div>{town.name}</div>
              </div>
              <div>
                <div className="font-bold">Область</div>
                <div>{town.region.nameUZ}</div>
              </div>
              <div>
                <div className="font-bold">Город/Район</div>
                <div>{town.city.nameUZ}</div>
              </div>
              <div>
                <div className="font-bold">Адрес</div>
                <div>{town.address}</div>
              </div>
            </div>
          </div>
        );
        getTownHospitals(town.cityId).then((res) => {
          const headers = ["Наименование", "Область", "Район/Город", "Адрес"];
          const showOnly = ["name", "region.nameUZ", "city.nameUZ", "address"];
          setHospitals(
            <DataTableView
              data={res.data.hospitals}
              headers={headers}
              showOnly={showOnly}
              onRowClicked={onRowClicked}
            />
          );
        });
      });
    })();
  }, []);
  return (
    <div className="bg-white px-1 py-8">
      {info}
      <div className="text-center font-bold p-5">
        <div className="text-black w-full">
          <IconLinksGroup links={links} />
        </div>
        <div className="w-full py-4">
          Поликлиники и врачебные пункты здравохранения
        </div>
        <div>{hospitals}</div>
      </div>
    </div>
  );
};

export default TownHealthCareOrgDetails;
