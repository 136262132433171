import React from "react";
import baby from "../assets/images/undraw_Baby_re_fr9r.png";

const AboutConts = () => {
  return (
    <div>
      <div className="p-16 my-5">
        <div className="flex md:flex-row flex-col  space-x-8 border border-gray-200 bg-white rounded-lg justify-center">
          <div className="basis-1/2 m-auto border-gray-200">
            <img src={baby} />
          </div>
          <div className="basis-1/2 flex-row space-y-7 text-justify m-auto p-12">
            <p className="uppercase font-bold">Что такое Контрацепция?</p>
            <p>
              Контрацепция - это не только способ предупреждения нежелательной
              беременности, но и способ сохранения репродуктивного здоровья.
              Разберемся, какие методы контрацепции существуют и рассмотрим
              каждый из них.
            </p>
            <p>
              Есть ли какой-то один универсальный метод контрацепции, который
              подходил бы всем, был удобным и защищал на 100%? К сожалению, нет.
              Существуют много разных методов контрацепции, у каждого есть свои
              плюсы, минусы и противопоказания. Никто не спорит, что нежеланную
              беременность лучше предупредить, чем ее прерывать и получить в
              будущем осложнения. Вреда от аборта намного больше, нежели от
              использования контрацептивов.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutConts;
