import React, { useEffect, useState } from "react";
import Heading from "../../../components/general/Heading";
import { getTownHealthcareOrgsPages } from "../../../api/requests/healthcareRequest";
import PagingTable from "../../../components/tables/PagingTable";
import { useNavigate } from "react-router";

const TownHealthOrgs = () => {
  const navigate = useNavigate();

  const onRowClicked = (town) => {
    navigate("/home/healthcare/town/" + town.id, {
      replace: true,
    });
  };

  const headers = [
    "Наименование учреждения",
    "Область",
    "Район/Город",
    "Адрес",
    "Номер телефона",
  ];

  const fields = ["name", "region.nameRU", "city.nameRU", "address", "phone"];

  return (
    <div className="w-full">
      <Heading>Районные и городские управления здравохранения</Heading>
      <div className="w-full">
        <PagingTable
          headers={headers}
          fields={fields}
          getDataFunction={getTownHealthcareOrgsPages}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
};

export default TownHealthOrgs;
