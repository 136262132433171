import axios from "axios";

const BASE_URL = "https://ebjfv.uz/api";
// const BASE_URL = "http://localhost:5000";
axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const instance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("cant find token");
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status == 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .get("/user/refresh", { withCredentials: true })
        .then((res) => {
          localStorage.setItem("token", res.data.accessToken);
          return instance(originalRequest);
        })
        .catch((err) => {
          return console.error(err.message);
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
