import React, { useEffect } from "react";
import NavigationLink from "./NavigationLink";
import { mainLinks } from "../links/mainlinks";
import LoginBtn from "../buttons/LoginBtn";
import logo from "../../assets/images/baby.webp";

const Navigation = () => {
  return (
    <div className="flex w-auto">
      <div className="flex justify-around w-full">
        <div>
          <img src={logo} width={60} />
        </div>
        <div className="flex my-auto">
          {mainLinks.map((item) => {
            return (
              <NavigationLink to={item.to} name={item.name} key={item.name} />
            );
          })}
        </div>
        <div className="flex-none">
          <LoginBtn />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
