import React from "react";
import Heading from "../../../components/general/Heading";
import { useNavigate } from "react-router-dom";
import PagingTable from "../../../components/tables/PagingTable";
import { getContrPages } from "../../../api/requests/healthcareRequest";

const ListOfContr = () => {
  const navigate = useNavigate();

  const onRowClicked = (contr) => {
    navigate("/home/healthcare/contr/" + contr.id, { replace: true });
  };

  const headers = ["Наименование", "Тип", "Добавлено"];

  const fields = ["name", "type", "createdAt"];

  return (
    <div className="w-full">
      <div className="w-full">
        <Heading>Список средств контрацептива</Heading>
        <div className="w-full">
          <PagingTable
            headers={headers}
            fields={fields}
            getDataFunction={getContrPages}
            onRowClicked={onRowClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default ListOfContr;
