export const mainLinks = [
  {
    name: "Главная",
    to: "/",
    icon: null,
  },
  {
    name: "О программе",
    to: "/about",
    icon: null,
  },
  {
    name: "О контрацептивах",
    to: "/contr",
    icon: null,
  },
];
