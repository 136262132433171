import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import femaleUserPic from "../../assets/images/undraw_Female_avatar_efig.png";
import maleUserPic from "../../assets/images/undraw_Male_avatar_g98d.png";
import format from "date-format";
import { getPatientInformation } from "../../api/requests/healthcareRequest";
import { maritalStatus, education } from "../../assets/arrays/roles";

const PatientProfile = ({ setPatient }) => {
  const [content, setContent] = useState("");

  const { id } = useParams();
  useEffect(() => {
    getPatientInformation(id)
      .then((res) => {
        const patientInfo = res.data.data;
        setPatient(patientInfo);
        setContent(
          <div className="flex w-full p-4">
            <div className="flex-1">
              <img
                className="max-w-md rounded-full"
                src={
                  patientInfo.photo == null
                    ? patientInfo.gender == "male"
                      ? maleUserPic
                      : femaleUserPic
                    : patientInfo.photo
                }
              />
            </div>
            <div className="grow p-4 bg-white border rounded-sm shadow-sm text-center content-center">
              <table className="w-full text-left text-sm text-gray-600 border-separate border-spacing-y-2">
                <thead>
                  <tr>
                    <td className="text-left" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                        <div>Личные данные</div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody className="before:block before:h-3">
                  <tr>
                    <td className="w-fit">
                      <b>Имя</b>
                    </td>
                    <td className="text-left">{patientInfo.firstName}</td>
                    <td>
                      <b>Фамилия</b>
                    </td>
                    <td className="text-left">{patientInfo.lastName}</td>
                  </tr>
                  <tr>
                    <td className="w-fit">
                      <b>Отчество</b>
                    </td>
                    <td className="text-left">{patientInfo.middleName}</td>
                    <td>
                      <b>Дата рождения</b>
                    </td>
                    <td className="text-left">{patientInfo.DOB}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Пол</b>
                    </td>
                    <td className="text-left">
                      {patientInfo.gender == "male" ? "Мужчина" : "Женщина"}
                    </td>
                    <td>
                      <b>ПИНФЛ</b>
                    </td>
                    <td className="text-left">{patientInfo.ssn}</td>
                  </tr>
                  <tr>
                    <td className="py-4" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                          />
                        </svg>
                        <div>Семейное положение</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Семейное положение</b>
                    </td>
                    <td className="text-left">
                      {
                        maritalStatus.filter((obj) => {
                          return obj.value === patientInfo.maritalStatus;
                        })[0].text
                      }
                    </td>
                    <td>
                      <b>Количество детей</b>
                    </td>
                    <td className="text-left">{patientInfo.numberOfKids}</td>
                  </tr>
                  <tr>
                    <td className="py-4" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                          />
                        </svg>

                        <div>Место работы и образование</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Место работы</b>
                    </td>
                    <td className="text-left">{patientInfo.placeOfWork}</td>
                    <td>
                      <b>Должность</b>
                    </td>
                    <td className="text-left">{patientInfo.position}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Образование</b>
                    </td>
                    <td className="text-left">
                      {
                        education.filter((obj) => {
                          return obj.value === patientInfo.education;
                        })[0].text
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="py-4" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                          />
                        </svg>

                        <div>Контактные данные</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-fit">
                      <b>Область</b>
                    </td>
                    <td className="text-left">{patientInfo.region.nameRU}</td>
                    <td>
                      <b>Адрес</b>
                    </td>
                    <td className="text-left align-top break-words" rowSpan={2}>
                      {patientInfo.address}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Город</b>
                    </td>
                    <td className="text-left">{patientInfo.city.nameRU}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Номер телефона</b>
                    </td>
                    <td>{patientInfo.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td className="py-4" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                          />
                        </svg>

                        <div>Учётные данные</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-fit">
                      <b>В системе</b>
                    </td>
                    <td className="text-left">
                      {format(
                        "dd/MM/yyyy",
                        format.parse(
                          format.ISO8601_WITH_TZ_OFFSET_FORMAT,
                          patientInfo.createdAt
                        )
                      )}
                    </td>
                    <td>
                      <b>Роль</b>
                    </td>
                    <td className="text-left">{patientInfo.user.role}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);
  return <div className="p-4 space-y-8">{content}</div>;
};

export default PatientProfile;
