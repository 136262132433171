import React from "react";

const Card = ({ header, children, actionMenu = "" }) => {
  return (
    <div>
      <div className="text-sm p-4 bg-sky-500 rounded-t-md text-white font-bold">
        {header}
        {actionMenu}
      </div>
      <div className="border">{children}</div>
    </div>
  );
};

export default Card;
