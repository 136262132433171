import React, { useState } from "react";
import Card from "../../../components/admin/cards/Card";
import { useNavigate } from "react-router";
import LeftActionMenu from "../../../components/admin/cards/LeftActionMenu";
import CustomInput from "../../../components/forms/CustomInput";
import { useForm } from "react-hook-form";
import CustomTextArea from "../../../components/forms/CustomTextArea";
import DefaultButton from "../../../components/buttons/DefaultButton";
import RegionsTownsSelect from "../../../components/forms/RegionsTownsSelect";
import { createHospital } from "../../../api/requests/healthcareRequest";
import FloatingMessage from "../../../components/general/FloatingMessage";

const AddEditHospital = (props) => {
  const [popUp, setPopUp] = useState({
    show: false,
    type: "success",
    heading: "",
    message: "",
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    formState: { isLoading },
  } = useForm();

  const onSubmit = async (data) => {
    createHospital(data)
      .then((res) => {
        reset();
        setPopUp({
          show: true,
          type: "success",
          heading: "Успешно!",
          message: res.data.message,
        });
      })
      .catch((err) => {
        setPopUp({
          show: true,
          type: "error",
          heading: "Ошибка!",
          message: err.response.data.message,
        });
      });
  };

  const textFields = [
    {
      name: "name",
      placeholder: "Наименование",
      label: "Наименование",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 100, message: "Не более 100 символов" },
      },
    },
  ];

  const addressArea = {
    name: "address",
    label: "Адрес",
    placeholder: "Адрес",
    validation: { required: "This field is required" },
    maxLength: { value: 150, message: "Not more than 150 characters" },
  };

  const actionMenu = (
    <LeftActionMenu>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer active:border"
        onClick={() => {
          navigate(-1);
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
        />
      </svg>
    </LeftActionMenu>
  );

  return (
    <div className="p-2">
      <FloatingMessage popUpState={popUp} setPopUpState={setPopUp} />
      <Card
        actionMenu={actionMenu}
        header="Добавить поликлинику или врачебный пункт"
      >
        <div className="p-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomInput
              {...textFields[0]}
              errors={
                errors[textFields[0].name] && errors[textFields[0].name].message
              }
              register={register}
            >
              {textFields[0].icon}
            </CustomInput>
            <CustomTextArea
              {...addressArea}
              register={register}
              errors={
                errors[addressArea.name] && errors[addressArea.name].message
              }
            ></CustomTextArea>

            <RegionsTownsSelect
              register={register}
              setValue={setValue}
              region={1}
            ></RegionsTownsSelect>
            <DefaultButton text="Сохранить" type="submit" />
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddEditHospital;
