import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import LeftActionMenu from "../../../components/admin/cards/LeftActionMenu";
import { useForm } from "react-hook-form";
import SimpleCardForm from "../../../components/forms/SimpleCardForm";
import { createNewUser } from "../../../api/requests/userRequests";
import { roles } from "../../../assets/arrays/roles";
import RegionsTownsSelect from "../../../components/forms/RegionsTownsSelect";
import PlaceOfWork from "../../../components/forms/PlaceOfWork";
import FloatingMessage from "../../../components/general/FloatingMessage";

const AddEditUser = () => {
  const [region, setRegion] = useState(null);
  const [town, setTown] = useState(null);
  const [errMessage, setErrMessage] = useState("");
  const password = useRef({});

  const [popUp, setPopUp] = useState({
    show: false,
    type: "success",
    heading: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    formState: { isLoading },
  } = useForm();

  password.current = watch("password", "");

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await createNewUser(data)
      .then((res) => {
        if (res.status == 200) {
          reset();
          setPopUp({
            show: true,
            type: "success",
            heading: "Успешно!",
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setErrMessage(err.response.data.message);
      });
  };

  const fields = [
    {
      name: "role",
      type: "select",
      label: "Тип пользывателя",
      icon: null,
      validation: {
        required: "Заполните поле",
      },
      options: roles,
    },
    {
      name: "username",
      placeholder: "Имя пользывателя (Номер телефона без символа '+')",
      label: "Имя пользывателя (Номер телефона без символа '+')",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        length: {
          value: 13,
          message: "Имя пользывателя (Номер телефона без символа '+')",
        },
      },
    },
    {
      name: "password",
      placeholder: "Пароль",
      label: "Пароль",
      type: "password",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
        minLength: { value: 8, message: "Не менее 8 символов" },
      },
    },
    {
      name: "re-password",
      placeholder: "Подтвердите пароль",
      label: "Подтвердите пароль",
      type: "password",
      icon: null,
      validation: {
        validate: (value) =>
          value === password.current || "Пароли не совпадают",
      },
    },
    {
      name: "firstName",
      placeholder: "Имя",
      label: "Имя",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "lastName",
      placeholder: "Фамилия",
      label: "Фамилия",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "middleName",
      placeholder: "Отчество",
      label: "Отчество",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "ssn",
      placeholder: "ПИНФЛ",
      label: "ПИНФЛ",
      type: "number",
      icon: null,
      validation: {
        required: "Заполните поле",
        pattern: {
          value: /^(\d{14})$/,
          message: "Введите правильное значение ПИНФЛ",
        },
      },
    },
    {
      name: "DOB",
      placeholder: "Дата рождение",
      label: "Дата рождение",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "gender",
      type: "select",
      label: "Пол",
      icon: null,
      validation: {
        required: "Заполните поле",
      },
      options: [
        {
          value: "male",
          text: "Мужской",
        },
        {
          value: "female",
          text: "Женский",
        },
      ],
    },
    {
      name: "phoneNumber",
      placeholder: "Номер телефона",
      label: "Номер телефона",
      type: "text",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="black"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v12m6-6H6"
          />
        </svg>
      ),
      validation: {
        required: "This field is required",
        maxLength: { value: 50, message: "Not more than 50 characters" },
        pattern: {
          value: /^(\d{3})?\(?\d{2}\)?\d{3}\d{4}$/,
          message: "Введите правильный номер телефона",
        },
      },
    },
    {
      name: "address",
      type: "textarea",
      label: "Адрес",
      placeholder: "Адрес",
      validation: { required: "This field is required" },
      maxLength: { value: 150, message: "Not more than 150 characters" },
    },
    {
      name: "regioncities",
      type: "regioncities",
      item: (
        <RegionsTownsSelect
          register={register}
          region={region}
          town={town}
          setValue={setValue}
        />
      ),
    },
    {
      name: "workplace",
      type: "workplace",
      item: <PlaceOfWork register={register} setValue={setValue} />,
    },
  ];

  const actionMenu = (
    <LeftActionMenu>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer active:border"
        onClick={() => {
          navigate(-1);
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
        />
      </svg>
    </LeftActionMenu>
  );

  return (
    <div className="p-2">
      <FloatingMessage popUpState={popUp} setPopUpState={setPopUp} />
      <SimpleCardForm
        title={"Добавить нового пользователя/Мед персонал"}
        register={register}
        errMessage={errMessage}
        errors={errors}
        fields={fields}
        onSubmit={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default AddEditUser;
