import React from "react";

const DefaultButton = ({ text, callBackFunction, type }) => {
  return (
    <div className="float-right p-2 pr-0">
      <button
        className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 
      focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 
      dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        onClick={callBackFunction}
        type={type}
      >
        {text}
      </button>
    </div>
  );
};

export default DefaultButton;
