import React from "react";
import Card from "../admin/cards/Card";
import CustomInput from "./CustomInput";
import { useForm } from "react-hook-form";
import CustomSelectInput from "./CustomSelectInput";
import DefaultButton from "../buttons/DefaultButton";
import CustomTextArea from "./CustomTextArea";

const SimpleCardForm = (props) => {
  const {
    formState: { errors },
  } = useForm();

  const renderCurrentSelection = (field) => {
    switch (field.type) {
      case "hidden":
        return (
          <input
            type="hidden"
            {...props.register(field.name, props.validation)}
            value={field.value}
          />
        );
      case "select":
        return (
          <CustomSelectInput
            {...field}
            errors={
              props.errors[field.name] && props.errors[field.name].message
            }
            register={props.register}
          >
            {field.icon}
          </CustomSelectInput>
        );

      case "text":
        return (
          <CustomInput
            {...field}
            errors={
              props.errors[field.name] && props.errors[field.name].message
            }
            register={props.register}
          >
            {field.icon}
          </CustomInput>
        );
      case "textarea":
        return (
          <CustomTextArea
            {...field}
            errors={
              props.errors[field.name] && props.errors[field.name].message
            }
            register={props.register}
          ></CustomTextArea>
        );
      case "regioncities":
        return field.item;

      case "contrs":
        return field.item;

      case "workplace":
        return field.item;

      case "password":
        return (
          <CustomInput
            {...field}
            errors={
              props.errors[field.name] && props.errors[field.name].message
            }
            register={props.register}
          >
            {field.icon}
          </CustomInput>
        );

      case "number":
        return (
          <CustomInput
            {...field}
            errors={
              props.errors[field.name] && props.errors[field.name].message
            }
            register={props.register}
          >
            {field.icon}
          </CustomInput>
        );
    }
  };

  return (
    <div className="p-2">
      <Card header={props.title} actionMenu={props.actionMenu}>
        <div className="p-6">
          <form onSubmit={props.onSubmit}>
            <div className="grid grid-cols-2">
              {props.fields.map((field, index) => {
                if (
                  field.type == "regioncities" ||
                  field.type == "workplace" ||
                  field.type == "contrs"
                ) {
                  return (
                    <div className="col-span-2" key={index}>
                      {renderCurrentSelection(field)}
                    </div>
                  );
                } else {
                  return <div key={index}>{renderCurrentSelection(field)}</div>;
                }
              })}
              <div className="text-red-500 p-2 text-sm">{props.errMessage}</div>
              {props.message !== null && (
                <div className="text-green-500 p-2 text-sm">
                  {props.message}
                </div>
              )}
              <DefaultButton text="Сохранить" type="submit" />
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default SimpleCardForm;
