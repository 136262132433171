import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LeftActionMenu from "../../../components/admin/cards/LeftActionMenu";
import RegionsTownsSelect from "../../../components/forms/RegionsTownsSelect";
import {
  getUserInformation,
  updateUserInformation,
} from "../../../api/requests/userRequests";
import { useState } from "react";
import SimpleCardForm from "../../../components/forms/SimpleCardForm";

const AddEditUserInfo = () => {
  const [region, setRegion] = useState(null);
  const [town, setTown] = useState(null);
  const [errMessage, setErrMessage] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    formState: { isLoading },
  } = useForm({
    defaultValues: async () => {
      await getUserInformation().then((res) => {
        setRegion(res.data.ui.regionId);
        setTown(res.data.ui.cityId);
        reset(res.data.ui);
      });
    },
  });

  const onSubmit = async (data) => {
    await updateUserInformation(data)
      .then((res) => {
        if (res.status == 200)
          navigate("/home/user/information", { replace: true });
      })
      .catch((err) => {
        setErrMessage(err.response.data.message);
      });
  };

  const fields = [
    {
      name: "firstName",
      placeholder: "Имя",
      label: "Имя",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "lastName",
      placeholder: "Фамилия",
      label: "Фамилия",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "middleName",
      placeholder: "Отчество",
      label: "Отчество",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "ssn",
      placeholder: "ПИНФЛ",
      label: "ПИНФЛ",
      type: "number",
      icon: null,
      validation: {
        required: "Заполните поле",
        length: { value: 14, message: "Не более 14 символов" },
      },
    },
    {
      name: "DOB",
      placeholder: "Дата рождение",
      label: "Дата рождение",
      type: "text",
      icon: null,
      validation: {
        required: "Заполните поле",
        maxLength: { value: 50, message: "Не более 50 символов" },
      },
    },
    {
      name: "gender",
      type: "select",
      label: "Пол",
      icon: null,
      validation: {
        required: "Заполните поле",
      },
      options: [
        {
          value: "male",
          text: "Мужской",
        },
        {
          value: "female",
          text: "Женский",
        },
      ],
    },
    {
      name: "phoneNumber",
      placeholder: "Номер телефона",
      label: "Номер телефона",
      type: "text",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="black"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v12m6-6H6"
          />
        </svg>
      ),
      validation: {
        required: "This field is required",
        maxLength: { value: 50, message: "Not more than 50 characters" },
        pattern: {
          value: /^(\d{3})?\(?\d{2}\)?\d{3}\d{4}$/,
          message: "Enter valid email address",
        },
      },
    },
    {
      name: "address",
      type: "textarea",
      label: "Адрес",
      placeholder: "Адрес",
      validation: { required: "This field is required" },
      maxLength: { value: 150, message: "Not more than 150 characters" },
    },
    {
      name: "regioncities",
      type: "regioncities",
      item: (
        <RegionsTownsSelect
          register={register}
          region={region}
          town={town}
          setValue={setValue}
        />
      ),
    },
  ];

  const actionMenu = (
    <LeftActionMenu>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer active:border"
        onClick={() => {
          navigate(-1);
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
        />
      </svg>
    </LeftActionMenu>
  );

  return (
    <div className="p-2">
      <SimpleCardForm
        title={"Редактировать личные данные пользователя"}
        register={register}
        errMessage={errMessage}
        errors={errors}
        fields={fields}
        onSubmit={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default AddEditUserInfo;
