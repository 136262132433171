import React from "react";

const WelcomeMessage = ({ user }) => {
  return (
    <div className="w-full p-6 bg-green-500 rounded-md text-white">
      Добро пожаловать в систему, {user.usersinfo.firstName}{" "}
      {user.usersinfo.lastName}!
    </div>
  );
};

export default WelcomeMessage;
