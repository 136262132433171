import React from "react";

const DataTableView = ({
  headers = [],
  data = [],
  showOnly = [],
  onRowClicked = null,
}) => {
  return (
    <div className="p-2">
      <table className=" text-sm w-full">
        <thead>
          <tr className="border-b-2">
            {headers.map((header) => {
              return (
                <th
                  key={header}
                  className="p-3 bg-slate-100 text-gray-600 uppercase text-center"
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                key={index}
                className="cursor-pointer border-b hover:border-lime-300 hover:bg-gray-100"
                onClick={() => onRowClicked(item)}
              >
                {showOnly.map((key) => {
                  if (key.includes(".")) {
                    const subs = key.split(".");
                    return (
                      <td className="p-2 py-2 text-center" key={key}>
                        {item[subs[0]][subs[1]]}
                      </td>
                    );
                  } else {
                    return (
                      <td className="px-4 py-4 text-center" key={key}>
                        {item[key]}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTableView;
