import React from "react";
import { Link } from "react-router-dom";

const SimpleLink = ({ to, children }) => {
  return (
    <Link className="my-auto text-sky-600 hover:underline" to={to}>
      {children}
    </Link>
  );
};

export default SimpleLink;
