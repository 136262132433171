import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NavigationLink = ({ label, to, icon }) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full p-3 border-b flex m-auto space-x-6 hover:bg-[#2f5a7d] hover:text-white cursor-pointer"
      onClick={() => {
        navigate(to, { replace: true });
      }}
    >
      <div>{icon}</div>
      <div>{label}</div>
    </div>
  );
};

export default NavigationLink;
