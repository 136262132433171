import React, { useState, useEffect } from "react";
import Card from "../cards/Card";
import LeftActionMenu from "../cards/LeftActionMenu";
import Modal from "../../modal/Modal";
import AddEditContrModal from "../../modal/pages/AddEditContrModal";
import { getLastContrs } from "../../../api/requests/healthcareRequest";
import DataTableView from "../DataTableView";
import DetailedHistoryOfWimex from "./DetailedHistoryOfWimex";

const HistoryOfContrsMini = ({ patient }) => {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [content, setContent] = useState();
  const [currWimex, setCurrWimex] = useState();

  const onRowClicked = (wimex) => {
    setModal2(!modal2);
    setCurrWimex(wimex);
  };

  useEffect(() => {
    if (patient !== undefined) {
      (async () => {
        getLastContrs({ userId: patient.userId, last: 5 })
          .then((res) => {
            if (res.data.data.length > 0) {
              setContent(
                <div className="overflow-scroll">
                  <DataTableView
                    onRowClicked={onRowClicked}
                    headers={[
                      "Наименование",
                      "Тип",
                      "Номер ордера",
                      "Доп.Инфо",
                    ]}
                    data={res.data.data}
                    showOnly={[
                      "contraceptive.name",
                      "type",
                      "orderNumber",
                      "comments",
                    ]}
                  ></DataTableView>
                </div>
              );
            } else {
              setContent(
                <div className="h-48 w-f text-center font-bold my-auto">
                  Ничего не найдено
                </div>
              );
            }
          })
          .catch((e) => {});
      })();
    }
  }, [patient]);

  const actionMenu = (
    <LeftActionMenu>
      <div className="flex flex-row">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer"
            onClick={() => {
              setModal(!modal);
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
      </div>
    </LeftActionMenu>
  );

  return (
    <div>
      <Modal showModal={modal} setShowModal={setModal}>
        <AddEditContrModal patient={patient} setModal={setModal} />
      </Modal>
      <Modal showModal={modal2} setShowModal={setModal2}>
        <DetailedHistoryOfWimex wimex={currWimex} setModal={setModal2} />
      </Modal>
      ;
      <Card
        header={"История об использовании контрацептивов"}
        actionMenu={actionMenu}
      >
        {content}
      </Card>
    </div>
  );
};

export default HistoryOfContrsMini;
