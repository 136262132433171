import React from "react";
import Heading from "../../../components/general/Heading";
import { useNavigate } from "react-router-dom";
import PagingTable from "../../../components/tables/PagingTable";
import { getHospitalsPages } from "../../../api/requests/healthcareRequest";

const ListOfHospitals = () => {
  const navigate = useNavigate();

  const onRowClicked = (hospital) => {
    navigate("/home/healthcare/hospital/" + hospital.id, { replace: true });
  };

  const headers = [
    "Наименование учреждения",
    "Область",
    "Район/Город",
    "Адрес",
    "Номер телефона",
    "Добавлен",
  ];

  const fields = [
    "name",
    "region.nameRU",
    "city.nameRU",
    "address",
    "phone",
    "createdAt",
  ];

  return (
    <div className="w-full">
      <div className="w-full">
        <Heading>Список добавленных больниц, поликлиник и мед. пунктов</Heading>
        <div className="w-full">
          <PagingTable
            headers={headers}
            fields={fields}
            getDataFunction={getHospitalsPages}
            onRowClicked={onRowClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default ListOfHospitals;
