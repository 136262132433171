import React from "react";
import {
  healthcareOrgLinks,
  personnel,
  patients,
  contrLinks,
} from "./adminlinks";
import NavigationLink from "./NavigationLink";

const NavigationMenu = () => {
  return (
    <div className="bg-slate-100 pb-20">
      <div className="p-2 float-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 hover:bg-gray-300 cursor-pointer rounded-full m-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </div>
      <div className="w-full p-3 border-b flex m-auto space-x-6 bg-[#2f5a7d] text-white font-bold font-mono uppercase">
        Мед управления
      </div>
      {healthcareOrgLinks.map((link) => {
        return (
          <NavigationLink
            to={link.to}
            icon={link.icon}
            label={link.name}
            key={link.name}
          />
        );
      })}
      <div className="w-full p-3 border-b flex m-auto space-x-6 bg-[#2f5a7d] text-white font-bold font-mono uppercase">
        Работа с персоналом
      </div>
      {personnel.map((link) => {
        return (
          <NavigationLink
            to={link.to}
            icon={link.icon}
            label={link.name}
            key={link.name}
          />
        );
      })}
      <div className="w-full p-3 border-b flex m-auto space-x-6 bg-[#2f5a7d] text-white font-bold font-mono uppercase">
        Работа с пациентами
      </div>
      {patients.map((link) => {
        return (
          <NavigationLink
            to={link.to}
            icon={link.icon}
            label={link.name}
            key={link.name}
          />
        );
      })}
      <div className="w-full p-3 border-b flex m-auto space-x-6 bg-[#2f5a7d] text-white font-bold font-mono uppercase">
        Контрацептивы
      </div>
      {contrLinks.map((link) => {
        return (
          <NavigationLink
            to={link.to}
            icon={link.icon}
            label={link.name}
            key={link.name}
          />
        );
      })}
    </div>
  );
};

export default NavigationMenu;
