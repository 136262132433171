import api from "../api";

export const getRegionsHealthCare = () => {
  return api.get("/healthcare/regions");
};

export const getTownHealthCare = () => {
  return api.get("/healthcare/towns");
};

export const getRegionTownHealthCare = (regionId) => {
  return api.get("/healthcare/regions/towns/" + regionId);
};

export const getRegionHealthcareOrgDetails = (regionId) => {
  return api.get("/healthcare/regions/details/" + regionId);
};

export const getTownHealthcareOrgDetails = (townId) => {
  return api.get("/healthcare/towns/details/" + townId);
};

export const createHospital = (data) => {
  return api.post("/healthcare/hospital/create", data);
};

export const getTownHospitals = (townId) => {
  return api.get("/healthcare/town/" + townId + "/hospitals");
};

export const getAllOrganizations = (keyword) => {
  return api.get("/healthcare/search/" + keyword);
};

export const createNewPatient = (data) => {
  return api.post("/healthcare/patient/create", data);
};

export const getAllPatiens = (data) => {
  return api.get("/healthcare/patient/list");
};

export const getPatientInformation = (id) => {
  return api.get("/healthcare/patient/" + id);
};

export const createContr = (data) => {
  return api.post("/healthcare/contr/create", data);
};

export const getContr = () => {
  return api.get("/healthcare/contr/getall");
};

export const createWimex = (data) => {
  return api.post("/healthcare/wimex/create", data);
};

export const getLastContrs = (data) => {
  return api.get("/healthcare/wimex/getlast/" + data.userId + "/" + data.last);
};

export const getTownHealthcareOrgsPages = (
  offset = 0,
  limit = 20,
  searchTerm = ""
) => {
  return api.get(
    "/healthcare/town/pages/" + offset + "/" + limit + "/" + searchTerm
  );
};

export const getHospitalsPages = (offset = 0, limit = 20, searchTerm = "") => {
  return api.get(
    "/healthcare/hospital/pages/" + offset + "/" + limit + "/" + searchTerm
  );
};

export const getPersonnelPages = (offset = 0, limit = 20, searchTerm = "") => {
  return api.get(
    "/healthcare/personnel/pages/" + offset + "/" + limit + "/" + searchTerm
  );
};

export const getContrPages = (offset = 0, limit = 20, searchTerm = "") => {
  return api.get(
    "/healthcare/contr/pages/" + offset + "/" + limit + "/" + searchTerm
  );
};
