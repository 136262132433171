import React from "react";
import { Link } from "react-router-dom";

const IconLinksGroup = ({ links }) => {
  return (
    <div className="flex space-x-0 w-fit">
      {links.map((link, index) => {
        return (
          <Link to={link.to} key={index}>
            {index == 0 ? (
              <div className="group relative overflow-hidden rounded-l-lg mx-0 bg-white border text-center content-center items-center justify-center">
                <div className="absolute left-0 inset-0 w-1 bg-sky-500 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                <span className="flex p-2 gap-1 relative items-center text-gray-900 group-hover:text-white">
                  {link.icon}
                  <span className="break-words text-sm">{link.text}</span>
                </span>
              </div>
            ) : index != links.length - 1 ? (
              <div className="group relative overflow-hidden bg-white border mx-0 text-center content-center items-center justify-center">
                <div className="absolute inset-0 w-1 bg-sky-500 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                <span className="flex p-2 gap-1 relative items-center text-gray-900 group-hover:text-white">
                  {link.icon}
                  <span className="break-words text-sm">{link.text}</span>
                </span>
              </div>
            ) : (
              <div className="group relative overflow-hidden bg-white rounded-r-lg border mx-0 text-center content-center items-center justify-center">
                <div className="absolute inset-y-0 right-0 w-1 bg-sky-500 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                <span className="flex p-2 gap-1 relative items-center text-gray-900 group-hover:text-white">
                  {link.icon}
                  <span className="break-words text-sm">{link.text}</span>
                </span>
              </div>
            )}
          </Link>
        );
      })}
    </div>
  );
};

export default IconLinksGroup;
