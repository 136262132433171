import React from "react";
import { useSelector } from "react-redux";
import WelcomeMessage from "../../../components/general/WelcomeMessage";

const Admin = () => {
  const user = useSelector((state) => state.user.user);
  return (
    <div className="flex relative">
      <div className="w-full">
        <WelcomeMessage user={user} />
      </div>
    </div>
  );
};

export default Admin;
