import React, { useState, useEffect } from "react";
import { getAllOrganizations } from "../../api/requests/healthcareRequest";

const PlaceOfWork = (props) => {
  const [selectedOrganization, setSelectedOrganization] = useState([0]);
  const [organizations, setOrganizations] = useState(null);
  const [searchOptions, setSearchOptions] = useState(
    <option key={0} value={0}>
      Не имеется
    </option>
  );

  const onOrganizationChange = (event) => {
    getAllOrganizations(event.target.value).then((res) => {
      setOrganizations(res.data.organizations);
    });
  };

  const onOrganizationSelect = (event) => {
    setSelectedOrganization([parseInt(event.target.value)]);
    props.setValue(
      "typeOfOrganization",
      event.target.selectedOptions[0].getAttribute("orgtype")
    );
    props.setValue("organizationId", selectedOrganization[1]);
  };

  useEffect(() => {
    if (organizations != null && organizations.length > 0) {
      setSearchOptions(
        organizations.map((elem) => {
          return elem.map((org) => {
            return (
              <option key={org.id} value={org.id} orgtype={org.TYPE}>
                {org.name}
              </option>
            );
          });
        })
      );
    }
  }, [organizations]);

  return (
    <>
      <div className="flex flex-row border border-gray-200 rounded-lg">
        <div className="flex flex-col mb-5 w-full p-3">
          <label className="mb-1 text-xs tracking-wide text-gray-600 flex">
            Поиск:
          </label>
          <div className="relative w-full">
            <input
              type="text"
              className="text-lg placeholder-gray-500 pl-2 pr-4 rounded-2xl border  
                    w-full py-2 focus:outline-none focus:border-blue-400 border-gray-400"
              onChange={onOrganizationChange}
            ></input>
          </div>
        </div>
        <div className="flex flex-col mb-5 w-full p-3">
          <label className="mb-1 text-xs tracking-wide text-gray-600 flex">
            Результаты
          </label>
          <div className="relative w-full">
            <select
              name="organizationId"
              multiple
              value={selectedOrganization}
              {...props.register("organizationId", {
                required: "Заполните данное поле",
                onChange: (e) => {
                  onOrganizationSelect(e);
                },
              })}
              className={`text-lg bg-white
                placeholder-gray-500 ${
                  props.children == null ? "pl-2" : "pl-10"
                } 
                pr-4 rounded-2xl border  
                w-full py-2 focus:outline-none 
                ${
                  props.errors != null
                    ? "focus:border-red-500 border-red-500"
                    : "focus:border-blue-400 border-gray-400"
                }`}
            >
              {searchOptions}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceOfWork;
