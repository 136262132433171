import React, { useEffect, useState } from "react";
import { getUser } from "../../api/apiRequests";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { user as setUser } from "../../store/user_slice";

const ProtectedRoute = ({ children }) => {
  const [authed, setAuthed] = useState(
    <div className="absolute left-0 top-0 bg-black bg-opacity-50 w-screen h-screen"></div>
  );
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const user = await getUser();
      if (user != null) {
        await dispatch(setUser(user));
        setAuthed(children);
      } else {
        setAuthed(<Navigate to="/" replace />);
      }
    })();
  }, []);

  return authed;
};

export default ProtectedRoute;
