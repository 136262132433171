import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getContr } from "../../api/requests/healthcareRequest";

const ContrSelect = (props) => {
  const [contrs, setContrs] = useState();
  const [contrsSelect, setContrsSelect] = useState();

  useEffect(() => {
    (async () => {
      getContr()
        .then((res) => {
          setContrsSelect(
            res.data.data.map((contr) => {
              return (
                <option key={contr.id} value={contr.id}>
                  {contr.name}
                </option>
              );
            })
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    })();
  }, []);

  useEffect(() => {
    console.log(contrs);
    if (contrs != null && contrs.lenght > 0) {
      setContrsSelect(
        contrs.map((contr) => {
          return <option value={contr.id}>{contr.name}</option>;
        })
      );
    }
  }, [contrs]);

  return (
    <div className="flex flex-col mb-5 w-full p-3">
      <label className="mb-1 text-xs tracking-wide text-gray-600 flex">
        Контрацептив:
      </label>
      <div className="relative w-full">
        <select
          {...props.register("contraceptiveId", {
            required: "Заполните данное поле",
          })}
          className={`text-lg bg-white
                    placeholder-gray-500 ${
                      props.children == null ? "pl-2" : "pl-10"
                    } 
                    pr-4 rounded-2xl border  
                    w-full py-2 focus:outline-none 
                    ${
                      props.errors != null
                        ? "focus:border-red-500 border-red-500"
                        : "focus:border-blue-400 border-gray-400"
                    }`}
        >
          {contrsSelect}
        </select>
      </div>
      {props.errors && (
        <p className="text-red-500 text-sm font-sans flex">{props.errors}</p>
      )}
    </div>
  );
};

export default ContrSelect;
