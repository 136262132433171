import React from "react";

import { useEffect } from "react";
import { getUserInformation } from "../../api/requests/userRequests";
import { useState } from "react";
import femaleUserPic from "../../assets/images/undraw_Female_avatar_efig.png";
import maleUserPic from "../../assets/images/undraw_Male_avatar_g98d.png";
import format from "date-format";

const UserProfile = ({ userId }) => {
  const [content, setContent] = useState("Loading...");
  useEffect(() => {
    (async () => {
      await getUserInformation(userId).then((res) => {
        const userInfo = res.data.ui;
        setContent(
          <div className="flex w-full p-4">
            <div className="flex-1">
              <img
                className="max-w-md rounded-full"
                src={
                  userInfo.photo == null
                    ? userInfo.gender == "male"
                      ? maleUserPic
                      : femaleUserPic
                    : userInfo.photo
                }
              />
            </div>
            <div className="grow p-4 bg-white border rounded-sm shadow-sm text-center content-center">
              <table className="w-full text-left text-sm text-gray-600 border-separate border-spacing-y-2">
                <thead>
                  <tr>
                    <td className="text-left" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                        <div>Личные данные</div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody className="before:block before:h-3">
                  <tr>
                    <td className="w-fit">
                      <b>Имя</b>
                    </td>
                    <td className="text-left">{userInfo.firstName}</td>
                    <td>
                      <b>Фамилия</b>
                    </td>
                    <td className="text-left">{userInfo.lastName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Пол</b>
                    </td>
                    <td className="text-left">
                      {userInfo.gender == "male" ? "Мужчина" : "Женщина"}
                    </td>
                    <td>
                      <b>ПИНФЛ</b>
                    </td>
                    <td className="text-left">{userInfo.ssn}</td>
                  </tr>
                  <tr>
                    <td className="py-4" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                          />
                        </svg>

                        <div>Контактные данные</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-fit">
                      <b>Область</b>
                    </td>
                    <td className="text-left">{userInfo.region.nameRU}</td>
                    <td>
                      <b>Адрес</b>
                    </td>
                    <td className="text-left align-top break-words" rowSpan={2}>
                      {userInfo.address}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Город</b>
                    </td>
                    <td className="text-left">{userInfo.city.nameRU}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Номер телефона</b>
                    </td>
                    <td>{userInfo.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td className="py-4" colSpan={4}>
                      <div className="flex font-bold uppercase space-x-2 text-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                          />
                        </svg>

                        <div>Учётные данные</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-fit">
                      <b>В системе</b>
                    </td>
                    <td className="text-left">
                      {format(
                        "dd/MM/yyyy",
                        format.parse(
                          format.ISO8601_WITH_TZ_OFFSET_FORMAT,
                          userInfo.createdAt
                        )
                      )}
                    </td>
                    <td>
                      <b>Роль</b>
                    </td>
                    <td className="text-left">{userInfo.user.role}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      });
    })();
  }, []);
  return <div className="flex m-auto">{content}</div>;
};

export default UserProfile;
