import React from "react";

const Modal = ({ showModal, setShowModal, children }) => {
  return (
    <>
      {showModal && (
        <div className="fixed w-full h-full bg-black bg-opacity-80 z-40 top-0 left-0">
          <div className="flex p-8 relative h-screen justify-center">
            <div className="flex justify-center m-auto  bg-white z-50 rounded-2xl opacity-100 bg-opacity-100">
              {children}
            </div>
            <div className="absolute right-2 top-2">
              <svg
                className="w-10 h-10 text-white cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowModal(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
