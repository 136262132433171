import React from "react";
import { Link } from "react-router-dom";

const IconLink = ({ icon, text, to }) => {
  return (
    <Link to={to}>
      <div className="group relative overflow-hidden rounded-lg bg-white shadow border text-center content-center items-center justify-center">
        <div class="absolute left-0 inset-0 w-3 bg-sky-500 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
        <span class="flex p-4 gap-1 relative items-center text-gray-900 group-hover:text-white">
          {icon}
          {text}
        </span>
      </div>
    </Link>
  );
};

export default IconLink;
