import React, { useEffect, useState } from "react";
import Card from "../../../components/admin/cards/Card";
import UserProfile from "../../../components/tables/UserProfile";
import LeftActionMenu from "../../../components/admin/cards/LeftActionMenu";
import { useNavigate } from "react-router-dom";

const UserInformation = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/home/user/information/edit", { replace: true });
  };
  const actionMenu = (
    <LeftActionMenu>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer active:border"
        onClick={handleNavigate}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
        />
      </svg>
    </LeftActionMenu>
  );
  return (
    <div className="p-4 space-y-8">
      <Card header="Информация о пользователе" actionMenu={actionMenu}>
        <UserProfile />
      </Card>
      <div className="flex flex-row space-x-10 justify-evenly">
        <div className="w-full">
          <Card header="Место работы "></Card>
        </div>
        <div className="w-full">
          <Card header="Место работы "></Card>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
