import React, { useEffect, useState } from "react";
import Card from "../cards/Card";
import { typeOfContrs } from "../../../assets/arrays/types";
import format from "date-format";

const DetailedHistoryOfWimex = ({ wimex, setModal }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    setContent(
      <Card
        header={
          <div className="flex flex-row">
            <div className="grow">
              Детали истории об использовании контрацептивов №{wimex.id}
            </div>
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 float-right cursor-pointer"
                onClick={() => {
                  setModal(false);
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        }
      >
        <div className="flex flex-col p-5">
          <div>
            <table className="w-full text-left text-sm text-gray-600 border-separate border-spacing-y-2">
              <tr>
                <td className="font-bold p-4">Контрацептив:</td>
                <td>{wimex.contraceptive.name}</td>
                <td className="font-bold  p-4">Тип контрацептива:</td>
                <td>
                  {
                    typeOfContrs.filter((obj) => {
                      return (obj.value = wimex.contraceptive.type);
                    })[0].text
                  }
                </td>
              </tr>
              <tr>
                <td className="font-bold p-4">Количество:</td>
                <td>{wimex.amount}</td>
                <td className="font-bold  p-4">Номер ордера:</td>
                <td>{wimex.orderNumber}</td>
              </tr>
              <tr>
                <td className="font-bold p-4">Дополнительная иформация:</td>
                <td colSpan={3}>{wimex.comments}</td>
              </tr>
              <tr>
                <td className="font-bold p-4">Создан:</td>
                <td colSpan={3}>
                  {format(
                    "dd/MM/yyyy",
                    format.parse(
                      format.ISO8601_WITH_TZ_OFFSET_FORMAT,
                      wimex.createdAt
                    )
                  )}
                </td>
              </tr>
            </table>
          </div>
          <div>
            <table>
              <tr></tr>
            </table>
          </div>
        </div>
      </Card>
    );
  }, [wimex]);
  return <div>{content}</div>;
};

export default DetailedHistoryOfWimex;
