import React, { useState } from "react";
import PatientProfile from "../../../components/tables/PatientProfile";
import LeftActionMenu from "../../../components/admin/cards/LeftActionMenu";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/admin/cards/Card";
import HistoryOfContrsMini from "../../../components/admin/patients/HistoryOfContrsMini";

const PatientView = () => {
  const [patient, setPatient] = useState();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home/healthcare/patient/edit", { replace: true });
  };

  const actionMenu = (
    <LeftActionMenu>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer active:border"
        onClick={handleNavigate}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
        />
      </svg>
    </LeftActionMenu>
  );

  const actionButtons = <div></div>;
  return (
    <div className="pb-10">
      <Card header="Информация о женщине" actionMenu={actionMenu}>
        <PatientProfile setPatient={setPatient} />
      </Card>
      <div className="flex flex-row pt-10 gap-4">
        <div className="basis-1/2">
          <HistoryOfContrsMini patient={patient} />
        </div>
      </div>
    </div>
  );
};

export default PatientView;
