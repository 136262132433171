import React from "react";
import Navigation from "../navigation/NavigationMain";

const Header = () => {
  return (
    <div className="border border-b-gray-300 shadow-md sticky top-0 z-10">
      <div className="p-4">
        <Navigation />
      </div>
    </div>
  );
};

export default Header;
